import { ServiceConfig } from '@telia-company/tv.common-sdk/dist/ServiceConfig';

enum SHAPE {
  cut,
  trimcut,
}

export interface ImageProxyUrlParams {
  url: string;
  width?: number;
  height?: number;
  shape?: SHAPE;
  retina?: boolean;
}

export const getImageProxyUrl = ({
  url,
  width,
  height,
  shape,
  retina,
}: ImageProxyUrlParams): string => {
  if (!url) {
    return '';
  }

  const protocol = url.match(/^\/\//) ? 'https:' : '';
  const source = protocol + url;
  const resize = width || height ? `${width || ''}x${height || ''}` : undefined;
  const imageProxy = ServiceConfig.getService('imageproxy');
  const cache = process.env.REACT_APP_IMAGE_PROXY_CACHE_BUST || '0';

  const queryString = Object.entries({
    shape,
    resize,
    source,
    retina,
    cache,
  })
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value || '')}`)
    .join('&');

  return imageProxy.getUrl(`/convert/?${queryString}`);
};
